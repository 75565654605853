import { useState } from "react";

import TextInput from "../../components/TextInput";
import { getMobileOperatingSystem, hRatio } from "../../constants/utils";
import UserStore from "../../stores/UserStore";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const isiOS = getMobileOperatingSystem() === "iOS";
const InputContainerStyle = {
  marginTop: 114 * hRatio,
  backgroundColor: "#F6F1E5",
};

const InputStyle = {
  backgroundColor: "#F6F1E5",
  borderColor: "black",
  height: isiOS ? 198 * hRatio : 172 * hRatio,
  fontSize: isiOS ? 69 * hRatio : 54 * hRatio,
};

const InputLabelStyle = {
  backgroundColor: "#F6F1E5",
  color: "black",
  height: 48 * hRatio,
  fontSize: isiOS ? 48 * hRatio : 39 * hRatio,
};

const InputErrorStyle = {
  fontSize: isiOS ? 48 * hRatio : 39 * hRatio,
};

const EmailPrompt = () => {
  const [email, setEmail] = useState(UserStore.email);
  const [error, setError] = useState(null);

  const handleEmailChange = (event) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
      setError("Enter valid email address");
      UserStore.setFields("isEmailValid", false);
    } else {
      setError("");
      UserStore.setFields("isEmailValid", true);
    }
    setEmail(event.target.value);
    UserStore.setFields("email", event.target.value);
  };

  return (
    <div style={{ marginTop: 414 * hRatio }}>
      <p className={isiOS ? iStyles.EmailPrompt : styles.EmailPrompt}>
        Please share your preferred email address
      </p>
      <p className={isiOS ? iStyles.EmailSubPrompt : styles.EmailSubPrompt}>
        Note that this will be the email ID used to create your Evolve account.
      </p>
      <TextInput
        title={"your email *"}
        placeholder={"enter email"}
        style={InputContainerStyle}
        onChange={handleEmailChange}
        error={error}
        type={"email"}
        inputStyle={InputStyle}
        labelStyle={InputLabelStyle}
        errorStyle={InputErrorStyle}
        value={email}
      />
    </div>
  );
};

export default EmailPrompt;
