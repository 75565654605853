import { useState } from "react";
import "./styles.css";

const ActionButton = ({ text, onClick, disabled, containerStyle }) => {
  const [isPressed, setIsPressed] = useState(false);
  return (
    <div className="ButtonContainer" style={containerStyle}>
      <input
        className="Button"
        style={{ opacity: isPressed || disabled ? 0.5 : 1 }}
        type="button"
        onClick={onClick}
        value={text}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        disabled={disabled}
      />
    </div>
  );
};

export default ActionButton;
