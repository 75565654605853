export const WHY_CHOOSE_BENEFITS = [
  {
    title: "Fast & Efficient",
    description:
      "We provide assessments within 24 hours, ensuring you don’t have to wait for the help you need.",
  },
  {
    title: "Expert Care",
    description:
      "Our dedicated team of professionals, ensures that every client receives personalised attention and a thorough diagnosis.",
  },
  {
    title: "Global Recognition",
    description:
      "Our reports are not only accepted by educational boards but also trusted by professionals worldwide, including experts globally.",
  },
];
