const USER_REVIEWS = [
  {
    id: 1,
    author: "Josephine Scarlet",
    description:
      "Hi this is a really good app. My mental health has never been any better. Thanks to the evolve team to make this app.",
    title: "Self care made simple",
  },
  {
    id: 3,
    author: "Melody Farmer",
    description:
      "I have found the perfect app for me. As I'm going through lots of changes in my life, this app has everything that I need.",
    title: "This app is amazing",
  },
  {
    id: 2,
    author: "May Walker",
    description:
      "I love the diversity and acceptance in the app and its design is ADORABLE. The breathing exercises are definitely one of my fav features.",
    title: "The best mental health app",
  },
  {
    id: 5,
    author: "Cheruby Sy",
    description:
      "Very easy to use and the design is so pretty. It gives you daily motivation with inspirational quotes, reminders for meditation, journaling and breathing...",
    title: "The perfect app for your self-care",
  },
];

export default USER_REVIEWS;
