import { hRatio, wRatio } from "../constants/utils";

const CheckCircleFilled = ({ height = 64, width = 63, color = "#31496e" }) => {
  return (
    <svg
      width={width * wRatio}
      height={height * hRatio}
      viewBox="0 0 63 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31.5" cy="32" r="31.5" fill={color} />
      <path
        opacity="0.89"
        d="M42.8051 21.5685L27.4701 36.921L20.1639 28.9918C19.2005 27.9796 17.6749 27.9796 16.7917 28.9075C15.8283 29.9197 15.8283 31.5225 16.7115 32.4504L25.7037 42.1512C26.1855 42.6573 26.8277 42.9103 27.3897 42.9103C27.9517 42.9103 28.5941 42.6573 29.0757 42.2354L46.1772 25.1957C47.1406 24.2678 47.221 22.6652 46.2574 21.6528C45.294 20.6406 43.7685 20.6406 42.8051 21.5685Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckCircleFilled;
