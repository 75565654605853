import { Observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import ActionButton from "../../components/ActionButton";
import {
  getMobileOperatingSystem,
  hRatio,
  triggerEvent,
  wRatio,
} from "../../constants/utils";
import { ACTION_BTN_TXT, SCORE_MEANING, SCORE_RECOMMENDATION } from "./config";
import styles from "./style.module.css";
import iStyles from "./iStyles.module.css";
import QuestionareStore from "../../stores/QuestionareStore";

const Score = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  const navigate = useNavigate();

  useEffect(() => {
    QuestionareStore.getTotalScore();
  }, []);

  const handleBtnPress = (isLow) => {
    navigate("/adhd/premium");
    triggerEvent("SCORE_SCREEN", {
      score: isLow ? "low" : "high",
    });
  };

  return (
    <Observer>
      {() => {
        const totalScores = QuestionareStore.totalScores;
        const isLow = totalScores <= 12;
        return (
          <div className={styles.ScoreContainer}>
            <p className={isiOS ? iStyles.ScoreTitle : styles.ScoreTitle}>
              Your ADHD score is
            </p>
            <p className={isiOS ? iStyles.ScoreSubTitle : styles.ScoreSubTitle}>
              {isLow ? "Low" : "High"}
            </p>
            <img
              src={require(isLow
                ? "../../images/tree.webp"
                : "../../images/hugging-heart.webp")}
              className={styles.ScoreIcon}
            />
            <div
              className={styles.ScoreCard}
              style={{ marginTop: 139 * hRatio }}
            >
              <p
                className={
                  isiOS ? iStyles.ScoreCardTitle : styles.ScoreCardTitle
                }
              >
                What does this mean?{" "}
              </p>
              <p
                className={
                  isiOS
                    ? iStyles.ScoreCardDescription
                    : styles.ScoreCardDescription
                }
              >
                {isLow ? SCORE_MEANING.low : SCORE_MEANING.high}
              </p>
            </div>
            <div
              className={styles.ScoreCard}
              style={{ marginTop: 54 * hRatio }}
            >
              <p
                className={
                  isiOS ? iStyles.ScoreCardTitle : styles.ScoreCardTitle
                }
              >
                Our recommendation
              </p>
              <p
                className={
                  isiOS
                    ? iStyles.ScoreCardDescription
                    : styles.ScoreCardDescription
                }
              >
                {isLow ? SCORE_RECOMMENDATION.low : SCORE_RECOMMENDATION.high}{" "}
              </p>
            </div>
            <ActionButton
              containerStyle={{
                padding: `0px ${63 * wRatio}px`,
                bottom: 54 * hRatio,
              }}
              text={ACTION_BTN_TXT.high}
              onClick={() => handleBtnPress(isLow)}
            />
          </div>
        );
      }}
    </Observer>
  );
};

export default Score;
