import { getMobileOperatingSystem, hRatio } from "../../constants/utils";
import Footer from "./Footer";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const DataScreen1 = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div>
      <p className={isiOS ? iStyles.DataScreen1Title : styles.DataScreen1Title}>
        75% of adults miss getting a
        <p style={{ margin: 0 }}>childhood ADHD diagnosis</p>
      </p>
      <div className={styles.MapImg}>
        <img
          src={require("../../images/Map.webp")}
          style={{ height: 1391 * hRatio }}
        />
      </div>
      <div className={styles.ImageText}>
        <p className={isiOS ? iStyles.ImageTitle : styles.ImageTitle}>75%</p>
        <p className={isiOS ? iStyles.ImageSubTitle : styles.ImageSubTitle}>
          Miss a childhood diagnosis
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DataScreen1;
