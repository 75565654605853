import { hRatio, wRatio } from "../constants/utils";

const CheckCircle = ({ height = 56, width = 56 }) => {
  return (
    <svg
      width={width * wRatio}
      height={height * hRatio}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.55">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 28C1 42.9117 13.0883 55 28 55C42.9117 55 55 42.9117 55 28C55 13.0883 42.9117 1 28 1C13.0883 1 1 13.0883 1 28ZM52.1572 27.9972C52.1572 41.3392 41.3413 52.1551 27.9993 52.1551C14.6572 52.1551 3.84138 41.3392 3.84138 27.9972C3.84138 14.6551 14.6572 3.83929 27.9993 3.83929C41.3413 3.83929 52.1572 14.6551 52.1572 27.9972Z"
          fill="black"
        />
        <path
          d="M28 54.5C13.3645 54.5 1.5 42.6355 1.5 28H0.5C0.5 43.1878 12.8122 55.5 28 55.5V54.5ZM54.5 28C54.5 42.6355 42.6355 54.5 28 54.5V55.5C43.1878 55.5 55.5 43.1878 55.5 28H54.5ZM28 1.5C42.6355 1.5 54.5 13.3645 54.5 28H55.5C55.5 12.8122 43.1878 0.5 28 0.5V1.5ZM1.5 28C1.5 13.3645 13.3645 1.5 28 1.5V0.5C12.8122 0.5 0.5 12.8122 0.5 28H1.5ZM27.9993 52.6551C41.6175 52.6551 52.6572 41.6154 52.6572 27.9972H51.6572C51.6572 41.0631 41.0652 51.6551 27.9993 51.6551V52.6551ZM3.34138 27.9972C3.34138 41.6154 14.3811 52.6551 27.9993 52.6551V51.6551C14.9334 51.6551 4.34138 41.0631 4.34138 27.9972H3.34138ZM27.9993 3.33929C14.3811 3.33929 3.34138 14.379 3.34138 27.9972H4.34138C4.34138 14.9313 14.9334 4.33929 27.9993 4.33929V3.33929ZM52.6572 27.9972C52.6572 14.379 41.6175 3.33929 27.9993 3.33929V4.33929C41.0652 4.33929 51.6572 14.9313 51.6572 27.9972H52.6572Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default CheckCircle;
