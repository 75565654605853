import styles from "./styles.module.css";

const LinearProgress = ({ progress }) => {
  return (
    <div className={styles.Bar}>
      <div className={styles.FillBar} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default LinearProgress;
