import { hRatio, wRatio } from "../constants/utils";

const LockIcon = ({ height = 54, width = 39 }) => {
  return (
    <svg
      width={width * wRatio}
      height={height * hRatio}
      viewBox="0 0 39 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.37946 15.2611C4.37946 6.85194 11.2185 0.0103817 19.6245 0C28.0306 0 34.8696 6.84156 34.8696 15.2508V20.5558H34.9734C37.1943 20.5558 39 22.3622 39 24.5839V42.5408C39 48.394 34.2569 53.1388 28.4059 53.1388H10.5941C4.74312 53.1388 0 48.394 0 42.5408V24.5943C0 22.3726 1.80575 20.5662 4.02661 20.5662H4.37946V15.2611ZM21.8856 36.6665C22.913 35.919 23.4216 34.8808 23.4216 33.6039C23.4008 31.7663 22.1243 30.2921 20.2874 29.9184C18.6374 29.5862 16.8835 30.5205 16.1674 32.1089C15.4306 33.7388 15.9184 35.5972 17.392 36.6873C17.6826 36.9053 17.7553 37.1233 17.7553 37.4555C17.7449 39.0854 17.7449 40.7154 17.7449 42.3453V42.3557C17.7449 43.7053 19.1666 44.7227 20.5884 43.996C21.1903 43.6949 21.5328 43.0305 21.5017 42.3557C21.5017 40.7257 21.5017 39.1062 21.4913 37.4763C21.4913 37.1129 21.5743 36.8949 21.8856 36.6665ZM11.0547 20.5679V15.2628C11.0547 10.5287 14.9049 6.6875 19.6268 6.6875C24.3591 6.6875 28.1989 10.5391 28.1989 15.2628V20.5679H11.0547Z"
        fill="white"
      />
    </svg>
  );
};

export default LockIcon;
