import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.css";
import iStyles from "./iStyles.module.css";
import LoadingAnimation from "./loader_animation.json";
import { getMobileOperatingSystem, wRatio } from "../../constants/utils";

const ScorePreparation = () => {
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  const handleAnimationComplete = () => {
    navigate("/adhd/scores");
  };

  return (
    <div className={styles.ScorePreparationContainer}>
      <p
        className={isiOS ? iStyles.PrepareScoreTitle : styles.PrepareScoreTitle}
      >
        We’re analyzing your results
      </p>
      <p
        className={
          isiOS ? iStyles.PrepareScoreSubTitle : styles.PrepareScoreSubTitle
        }
      >
        A timely diagnosis of Adult ADHD can be life-changing.{" "}
      </p>
      <img src={require("../../images/tree.webp")} className={styles.Tree} />
      <div className={styles.Loader}>
        <Lottie
          animationData={LoadingAnimation}
          loop={false}
          onComplete={handleAnimationComplete}
          style={{
            width: 467 * wRatio,
          }}
        />
      </div>
      <img
        src={require("../../images/cloud3.webp")}
        className={styles.Cloud3}
      />
    </div>
  );
};

export default ScorePreparation;
