import axios from "axios";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { getMobileOperatingSystem } from "../constants/utils";

class UserStore {
  email = "";
  password = "1234";
  developerId = "";
  isEmailValid = true;
  deviceId = uuidv4();
  accessToken = "";

  constructor() {
    makeAutoObservable(this);
  }

  setFields = (key, value) => {
    this[key] = value;
  };

  guestOnboard = async () => {
    let ADID =
      getMobileOperatingSystem() === "iOS"
        ? "00000000-0000-0000-0000-000000000000"
        : "00000000-0000-0000-000000000000";

    const params = {
      device_id: this.deviceId,
      ad_id: ADID,
      is_web: true,
    };

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/guest/onboard/`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.developerId = res?.data?.developer_id[0];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  createUserAccount = async () => {
    let url =
      "https://no-encrrryption---evolve-api-3yp7emdj3q-uc.a.run.app/api/v7/user/webflow/signup/";
    if (this.developerId === "") {
      await this.guestOnboard();
    }

    const params = {
      email: this.email.toLowerCase(),
      password: this.password,
      password2: this.password,
      profile: {
        is_webflow: true,
        webflow_pwd_created: false,
      },
      guest: {
        developer_id: this.developerId,
      },
      device: {
        device_id: this.deviceId,
      },
    };

    axios
      .post(`${url}`, params)
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  loginUser = async () => {
    const url = `https://no-encrrryption---evolve-api-3yp7emdj3q-uc.a.run.app/api/v7/user/email/login/`;
    const CLIENT_ID = "AiWaiUrd6zx0BPSDjx0h6jstz0BpSbbOyrTWkkAD";
    const CLIENT_SECRET =
      "VfsYbPZg4nQAP4xOohoqyAV8rGaEgGTTvUP1oxKUaKcfEafoewQ0RHTj1c6c9VkCOu71ByeLU9fi1WOvQMgBewlceHlejGXbi510RMOAd6YvHgw9vDf32YMOCNVy1AvZ";
    const GRANT_TYPE = "password";

    const params = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: GRANT_TYPE,
      username: this.email.toLowerCase(),
      password: "1234",
      "guest.developer_id": this.developerId,
      "device.device_id": this.deviceId,
      guest: {
        developer_id: this.developerId,
      },
      device: {
        device_id: this.deviceId,
      },
    };

    let qsConvertedString = "";
    Object.entries(params).forEach(([key, value]) => {
      qsConvertedString = `${qsConvertedString}${key}=${value}&`;
    });

    await axios
      .post(`${url}`, qsConvertedString, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        this.accessToken = res?.data?.access_token;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  updateUserEmail = async () => {
    const url =
      "https://no-encrrryption---evolve-api-3yp7emdj3q-uc.a.run.app/api/v7/user/update/";

    await this.loginUser();

    const params = {
      user: {
        email: this.email.toLowerCase(),
        password: this.password,
      },
      profile: {
        webflow_pwd_created: true,
      },
    };

    axios
      .put(`${url}`, params, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
      .then(async (res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  markUserPremium = () => {
    const params = {
      email: this.email,
    };

    axios
      .put(
        `https://no-encrrryption---evolve-api-3yp7emdj3q-uc.a.run.app/api/v7/user/premium_status/`,
        params
      )
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };
}

export default new UserStore();
