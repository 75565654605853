import ActionButton from "../../components/ActionButton";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { getMobileOperatingSystem } from "../../constants/utils";
import Evolve from "../../icons/Evolve";

const DownloadApp = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  const handleBtnPress = () => {
    if (getMobileOperatingSystem() === "iOS") {
      window.location.href =
        "https://apps.apple.com/in/app/evolve-selfcare-for-beginners/id1515433542";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=in.evolve.android&hl=en_IN";
    }
  };

  return (
    <div className={styles.DownloadAppBG}>
      <div className={styles.EvolveIcon}>
        <Evolve />
      </div>
      <p className={isiOS ? iStyles.Title : styles.Title}>Download the app</p>
      <p className={isiOS ? iStyles.SubTitle : styles.SubTitle}>
        Evolve is best when you use it on the app. Download it, and get powerful
        ideas when you need them: anytime, anywhere!
      </p>
      <img
        src={require("../../assets/download_app_screen/download_app_bg.webp")}
        style={{
          height: "100%",
          width: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <ActionButton text={"Download Evolve"} onClick={handleBtnPress} />
    </div>
  );
};

export default DownloadApp;
