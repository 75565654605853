import { useEffect } from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import Questionare from "./screens/Questionare";
import { setDimensionsRatio } from "./constants/utils";
import Metrics from "./screens/Metrics";
import WelcomeScreen from "./screens/WelcomeScreen";
import Loading from "./screens/Loading";
import SignUp from "./screens/SignUp";
import DownloadApp from "./screens/DownloadApp";
import PremiumScreen from "./screens/PremiumScreen";
import ADHDApp from "./adhd_web_flow/App";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WelcomeScreen />,
    },
    {
      path: "/metrics",
      element: <Metrics />,
    },
    {
      path: "/questionare",
      element: <Questionare />,
    },
    {
      path: "/loading",
      element: <Loading />,
    },
    {
      path: "/premium",
      element: <PremiumScreen />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/download",
      element: <DownloadApp />,
    },
    {
      path: "/adhd/*",
      element: <ADHDApp />,
    },
  ]);

  useEffect(() => {
    setDimensionsRatio();

    ReactPixel.init(
      "2763359800559914",
      {},
      {
        autoConfig: true,
        debug: false,
      }
    );
    ReactGA.initialize("G-FNZK4CF0YD");
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
