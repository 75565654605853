import BenefitsSection from "./BenefitsSection";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import ContentTags from "../../../assets/payment_screen/content_tags.webp";
import InclusiveHealthApp from "../../../assets/payment_screen/inclusive_health_app.webp";
import GoogleAppAward from "../../../assets/payment_screen/best_app_award_dark.webp";
import AppleEditorChoice from "../../../assets/payment_screen/apple_editor_choice.webp";
import { getMobileOperatingSystem } from "../../../constants/utils";

const PremiumBenefits = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div className={Styles.Container}>
      <div className={Styles.FreeTrialCard}>
        <p className={isiOS ? iStyles.FreeTrialTitle : Styles.FreeTrialTitle}>
          How to cancel free trial?
        </p>
        <p className={isiOS ? iStyles.FreeTrialText : Styles.FreeTrialText}>
          Subscription terms:
          <p style={{ margin: 0 }}>
            Please email us at members@evolveinc.io to gift a subscription to a
            friend or for any issues you need help with
          </p>
        </p>
      </div>
      <BenefitsSection />
      <p className={isiOS ? iStyles.ContentTitle : Styles.ContentTitle}>
        Content designed to<p style={{ margin: 0 }}>solve your problems</p>
      </p>
      <img src={ContentTags} className={Styles.ContentTags} />
      <div className={Styles.ImagesContainer}>
        <img src={InclusiveHealthApp} className={Styles.InclusiveAppImg} />
        <img src={GoogleAppAward} className={Styles.BestAppAwardImg} />
        <img src={AppleEditorChoice} className={Styles.AppleEditorImg} />
      </div>
    </div>
  );
};

export default PremiumBenefits;
