import { getMobileOperatingSystem } from "../../constants/utils";
import Footer from "./Footer";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const DataScreen2 = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div>
      <p className={isiOS ? iStyles.DataScreen2Title : styles.DataScreen2Title}>
        Undiagnosed ADHDis a hidden burden of
      </p>
      <p
        className={
          isiOS ? iStyles.DataScreen2SubTitle : styles.DataScreen2SubTitle
        }
      >
        $14,576/year
      </p>
      <img
        src={require("../../images/Coins.webp")}
        className={styles.CoinsImg}
      />
      <Footer />
    </div>
  );
};

export default DataScreen2;
