import { hRatio, wRatio } from "../constants/utils";

const CrossEye = ({ height = 69, width = 89 }) => {
  return (
    <svg
      width={width * wRatio}
      height={height * hRatio}
      viewBox="0 0 89 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.55">
        <path
          d="M44.6488 52.0874C49.4535 52.0874 53.5343 50.4057 56.8913 47.0424C60.2482 43.679 61.9267 39.5949 61.9267 34.7901C61.9267 29.9854 60.2451 25.9046 56.8817 22.5476C53.5183 19.1906 49.4342 17.5122 44.6295 17.5122C39.8247 17.5122 35.7439 19.1938 32.3869 22.5571C29.03 25.9206 27.3515 30.0046 27.3515 34.8094C27.3515 39.6141 29.0331 43.695 32.3965 47.0519C35.7599 50.4089 39.844 52.0874 44.6488 52.0874ZM44.6391 46.2488C41.4588 46.2488 38.7556 45.1357 36.5294 42.9095C34.3032 40.6833 33.1901 37.9801 33.1901 34.7998C33.1901 31.6195 34.3032 28.9162 36.5294 26.69C38.7556 24.4638 41.4588 23.3507 44.6391 23.3507C47.8194 23.3507 50.5226 24.4638 52.7488 26.69C54.975 28.9162 56.0882 31.6195 56.0882 34.7998C56.0882 37.9801 54.975 40.6833 52.7488 42.9095C50.5226 45.1357 47.8194 46.2488 44.6391 46.2488ZM44.6449 64.4824C34.8937 64.4824 26.0087 61.7927 17.99 56.4134C9.97134 51.0342 4.06746 43.8296 0.27832 34.7998C4.06746 25.7699 9.96944 18.5654 17.9843 13.1861C25.999 7.80683 34.882 5.11719 44.6333 5.11719C54.3845 5.11719 63.2695 7.80683 71.2882 13.1861C79.3069 18.5654 85.2108 25.7699 88.9999 34.7998C85.2108 43.8296 79.3088 51.0342 71.294 56.4134C63.2792 61.7927 54.3962 64.4824 44.6449 64.4824ZM44.6391 58.1219C52.6252 58.1219 59.9575 56.0194 66.6361 51.8143C73.3147 47.6093 78.4208 41.9378 81.9545 34.7998C78.4208 27.6618 73.3147 21.9903 66.6361 17.7852C59.9575 13.5802 52.6252 11.4777 44.6391 11.4777C36.653 11.4777 29.3207 13.5802 22.6421 17.7852C15.9635 21.9903 10.8574 27.6618 7.32372 34.7998C10.8574 41.9378 15.9635 47.6093 22.6421 51.8143C29.3207 56.0194 36.653 58.1219 44.6391 58.1219Z"
          fill="black"
          fill-opacity="0.55"
        />
        <path
          d="M10.7158 3L80.9851 66"
          stroke="black"
          stroke-opacity="0.55"
          stroke-width="5.65385"
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default CrossEye;
