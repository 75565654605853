import USER_REVIEWS from "../../constants/user_reviews";
import TestimonialCard from "../Metrics/TestimonialCard";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { getMobileOperatingSystem } from "../../constants/utils";

const UserReviews = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div className={Styles.ReviewsContainer}>
      <p className={isiOS ? iStyles.ReviewsTitle : Styles.ReviewsTitle}>
        People ❤️ Evolve
      </p>
      <p className={isiOS ? iStyles.ReviewsSubtitle : Styles.ReviewsSubtitle}>
        become part of 1 million+ growing community
      </p>
      <div className={Styles.UserReviews}>
        <TestimonialCard
          description={USER_REVIEWS[0].description}
          author={USER_REVIEWS[0].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[1].description}
          author={USER_REVIEWS[1].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[2].description}
          author={USER_REVIEWS[2].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[3].description}
          author={USER_REVIEWS[3].author}
        />
      </div>
    </div>
  );
};

export default UserReviews;
