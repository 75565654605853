import { useNavigate } from "react-router-dom";

import ActionButton from "../../components/ActionButton";
import Evolve from "../../icons/Evolve";
import TestimonialCard from "./TestimonialCard";
import USER_REVIEWS from "../../constants/user_reviews";
import { getMobileOperatingSystem, triggerEvent } from "../../constants/utils";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const Metrics = () => {
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  const handleBtnPress = () => {
    navigate("/loading");
    triggerEvent("WTA-See_Personalized_Plan_Btn");
  };
  return (
    <div className={Styles.MetricsContainer}>
      <div className={Styles.EvolveIcon}>
        <Evolve />
      </div>
      <p className={isiOS ? iStyles.MetricsTitle : Styles.MetricsTitle}>
        See an 67% increase in your mental wellness scores in four weeks
      </p>
      <img
        className={Styles.Graph}
        src={require("../../assets/metrics/graph.webp")}
      />
      <div className={Styles.Testimonials}>
        <TestimonialCard
          description={USER_REVIEWS[0].description}
          author={USER_REVIEWS[0].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[1].description}
          author={USER_REVIEWS[1].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[2].description}
          author={USER_REVIEWS[2].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[3].description}
          author={USER_REVIEWS[3].author}
        />
      </div>
      <ActionButton
        text={"See my personalized plan"}
        onClick={handleBtnPress}
      />
    </div>
  );
};

export default Metrics;
