import PremiumBenefits from "./PremiumBenefits";
import PremiumPlans from "./PremiumPlans";
import Styles from "./styles.module.css";
import UserReviews from "./UserReviews";

const PremiumScreen = () => {
  return (
    <div className={Styles.PremiumScreenContainer}>
      <img
        src={require("../../assets/payment_screen/payment_screen_cover.webp")}
        className={Styles.PremiumCoverImage}
      />
      <img
        src={require("../../assets/payment_screen/best_app_award.webp")}
        className={Styles.GooglePlayAward}
      />
      <PremiumPlans />
      <PremiumBenefits />
      <UserReviews />
      <PremiumPlans />
    </div>
  );
};

export default PremiumScreen;
