import { getMobileOperatingSystem } from "../../../constants/utils";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const BENEFITS = [
  {
    id: 1,
    title: "100+",
    textLine1: "sleep audios,",
    textLine2: "meditations & music",
  },
  {
    id: 2,
    title: "7 day",
    textLine1: "free trial,",
    textLine2: "cancel anytime",
  },
  {
    id: 3,
    title: "50+",
    textLine1: "CBT therapies for",
    textLine2: "your healing journey",
  },
  {
    id: 4,
    title: "100%",
    textLine1: "of content make you",
    textLine2: "a better person",
  },
];

const BenefitsSection = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div className={Styles.BenefitsSection}>
      {BENEFITS.map((item) => (
        <div className={Styles.BenefitCard}>
          <p className={isiOS ? iStyles.BenefitTitle : Styles.BenefitTitle}>
            {item.title}
          </p>
          <p className={isiOS ? iStyles.BenefitText : Styles.BenefitText}>
            {item.textLine1}
            <p style={{ margin: 0 }}>{item.textLine2}</p>
          </p>
        </div>
      ))}
    </div>
  );
};

export default BenefitsSection;
