import USER_REVIEWS from "../../../constants/user_reviews";
import { getMobileOperatingSystem } from "../../../constants/utils";
import TestimonialCard from "../TestimonialCard";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const UserReviews = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div>
      <div className={styles.Container1}>
        <div className={styles.ContentHoursCard}>
          <p className={isiOS ? iStyles.ContentHours : styles.ContentHours}>
            25,000+
          </p>
          <p className={isiOS ? iStyles.CardSubTitle : styles.CardSubTitle}>
            hours of care delivered
          </p>
        </div>
        <div className={styles.AvgRatingCard}>
          <p className={isiOS ? iStyles.AvgRating : styles.AvgRating}>
            ⭐️4.8/5
          </p>
          <p className={isiOS ? iStyles.CardSubTitle : styles.CardSubTitle}>
            ratings from our <p style={{ margin: 0 }}>patients</p>
          </p>
        </div>
      </div>
      <div className={styles.ReviewsContainer}>
        <p className={isiOS ? iStyles.ReviewsTitle : styles.ReviewsTitle}>
          People ❤️ Embrace
        </p>
        <p className={isiOS ? iStyles.ReviewsSubtitle : styles.ReviewsSubtitle}>
          become part of 1 million+ growing community
        </p>
        <div className={styles.UserReviews}>
          <TestimonialCard
            description={USER_REVIEWS[0].description}
            author={USER_REVIEWS[0].author}
          />
          <TestimonialCard
            description={USER_REVIEWS[1].description}
            author={USER_REVIEWS[1].author}
          />
          <TestimonialCard
            description={USER_REVIEWS[2].description}
            author={USER_REVIEWS[2].author}
          />
          <TestimonialCard
            description={USER_REVIEWS[3].description}
            author={USER_REVIEWS[3].author}
          />
        </div>
      </div>
    </div>
  );
};

export default UserReviews;
