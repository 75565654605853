import styles from "./styles.module.css";

const TextInput = ({
  id = "",
  title,
  placeholder,
  value,
  onChange,
  style,
  error,
  inputStyle,
  labelStyle,
  errorStyle,
  isEditable = true,
  inlineCustomStyle = `{}`,
}) => {
  return (
    <div className={styles.InputContainer} style={style}>
      <div className={styles.InputTitleContainer}>
        <p className={styles.InputTitle} style={labelStyle}>
          {title}
        </p>
      </div>
      <input
        id={id}
        className={styles.TextInput}
        placeholder={placeholder}
        type={"email"}
        onChange={onChange}
        autoComplete="current-password"
        style={inputStyle}
        value={value}
        disabled={!isEditable}
      />
      <style>{inlineCustomStyle}</style>

      {error && (
        <p className={styles.InputError} style={errorStyle}>
          {error}
        </p>
      )}
    </div>
  );
};

export default TextInput;
