import styles from "./styles.module.css";
import CostBreakUp from "./CostBreakUp";
import { WHY_CHOOSE_BENEFITS } from "./config";
import { getMobileOperatingSystem } from "../../../constants/utils";
import iStyles from "./iStyles.module.css";

const WhyChooseUs = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  const renderCards = () => {
    const recognitionComment = () => {
      return (
        <div className={styles.RecognitionCard}>
          <p
            className={
              isiOS
                ? iStyles.RecognitionDescription
                : styles.RecognitionDescription
            }
          >
            “eMbrace offers services at a level we find back home in the United
            States.”
          </p>
          <p
            className={
              isiOS ? iStyles.RecognitionAuthor : styles.RecognitionAuthor
            }
          >
            Andrew
          </p>
          <p
            className={
              isiOS
                ? iStyles.RecognitionDesignation
                : styles.RecognitionDesignation
            }
          >
            Unit Chief for Bhutan
          </p>
          <p
            className={
              isiOS
                ? iStyles.RecognitionDesignation
                : styles.RecognitionDesignation
            }
          >
            at the U.S. Embassy
          </p>
        </div>
      );
    };
    return (
      <div>
        {WHY_CHOOSE_BENEFITS.map((item, index) => (
          <div className={styles.Card}>
            <p className={isiOS ? iStyles.CardTitle : styles.CardTitle}>
              {item.title}
            </p>
            <p className={isiOS ? iStyles.CardSubTitle : styles.CardSubTitle}>
              {item.description}
            </p>
            {index === 2 && recognitionComment()}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.WhyChooseUsContainer}>
      <p className={isiOS ? iStyles.Title : styles.Title}>Why choose us?</p>
      <CostBreakUp />
      {renderCards()}
    </div>
  );
};

export default WhyChooseUs;
