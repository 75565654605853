import Styles from "./styles.module.css";

const TestimonialCard = ({ description, author }) => {
  return (
    <div className={Styles.TestimonialCard}>
      <div className={Styles.Ratings}>
        <img
          src={require("../../assets/metrics/rating_stars.webp")}
          className={Styles.RatingIcon}
        />
      </div>
      <p className={Styles.Testimonial}>
        “{description}”
      </p>
      <p className={Styles.UserName}>{author}.</p>
    </div>
  );
};

export default TestimonialCard;
