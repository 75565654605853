import { getMobileOperatingSystem } from "../../../constants/utils";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const TestimonialCard = ({ description, author }) => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div className={isiOS ? iStyles.TestimonialCard : styles.TestimonialCard}>
      <div className={styles.Ratings}>
        <img
          src={require("../../../images/rating_stars.webp")}
          className={styles.RatingIcon}
        />
      </div>
      <p className={isiOS ? iStyles.Testimonial : styles.Testimonial}>
        “{description}”
      </p>
      <p className={isiOS ? iStyles.UserName : styles.UserName}>{author}.</p>
    </div>
  );
};

export default TestimonialCard;
