import styles from "./styles.module.css";
import Tick from "../../icons/Tick";
import isStyles from "./iStyles.module.css";
import { getMobileOperatingSystem } from "../../constants/utils";

const MCQOption = ({ isSelected, text, onClick }) => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  return (
    <div
      className={styles.MCQOption}
      style={{
        backgroundColor: isSelected
          ? "rgba(0, 0, 0, 0.39)"
          : "rgba(0, 0, 0, 0.10)",
      }}
      onClick={onClick}
    >
      <p
        className={isiOS ? isStyles.MCQText : styles.MCQText}
        style={{ color: isSelected ? "white" : "black" }}
      >
        {text}
      </p>
      <div>{isSelected && <Tick />}</div>
    </div>
  );
};

export default MCQOption;
