import { useState } from "react";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { getMobileOperatingSystem } from "../../constants/utils";

const ActionButton = ({
  text,
  onClick,
  disabled,
  containerStyle,
  buttonStyle = {},
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div className={styles.ButtonContainer} style={containerStyle}>
      <input
        className={isiOS ? iStyles.Button : styles.Button}
        style={{ opacity: isPressed || disabled ? 0.5 : 1, ...buttonStyle }}
        type="button"
        onClick={onClick}
        value={text}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        disabled={disabled}
      />
    </div>
  );
};

export default ActionButton;
