import Cloud1 from "../../icons/Cloud1";
import Cloud2 from "../../icons/Cloud2";
import styles from "./styles.module.css";

const Background = ({ children }) => {
  return (
    <div className={styles.Background}>
      <div className={styles.Cloud1}>
        <Cloud1 />
      </div>
      <div className={styles.Cloud2}>
        <Cloud2 />
      </div>
      <img
        src={require("../../images/cloud3.webp")}
        className={styles.Cloud3}
      />
      {children}
    </div>
  );
};

export default Background;
