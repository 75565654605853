import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";

import styles from "./App.module.css";
import { setDimensionsRatio } from "./constants/utils";
import Welcome from "./screens/Welcome";
import ScorePreparation from "./screens/ScorePreparation";
import Score from "./screens/Score";
import Questionare from "./screens/Questionare";
import Premium from "./screens/Premium";

function ADHDApp() {
  useEffect(() => {
    setDimensionsRatio();
  }, []);

  return (
    <div className={styles.App}>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/questionare" element={<Questionare />} />
        <Route path="/score_preparation" element={<ScorePreparation />} />
        <Route path="/scores" element={<Score />} />
        <Route path="/premium" element={<Premium />} />
      </Routes>
    </div>
  );
}

export default ADHDApp;
