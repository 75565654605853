import { getMobileOperatingSystem } from "../../constants/utils";
import Tick from "../../icons/Tick";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const MCQOption = ({ isSelected, text, onClick }) => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  return (
    <div
      className={Styles.MCQOption}
      style={{
        backgroundColor: isSelected
          ? "rgba(0, 0, 0, 0.55)"
          : "rgba(0, 0, 0, 0.21)",
      }}
      onClick={onClick}
    >
      <p className={isiOS ? iStyles.MCQText : Styles.MCQText}>{text}</p>
      <div>{isSelected && <Tick />}</div>
    </div>
  );
};

export default MCQOption;
