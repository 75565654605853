import {
  getMobileOperatingSystem,
  hRatio,
  wRatio,
} from "../../../constants/utils";
import { ADHD_Pointers } from "../config";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const WhoAreWe = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  const renderADHDPointers = () => {
    return ADHD_Pointers.map((item) => (
      <div className={styles.ADHDPointerCard}>
        <img src={item.icon} className={styles.ADHDPointerCardIcon} />
        <div style={{ marginLeft: 39 * wRatio }}>
          <p
            className={
              isiOS ? iStyles.ADHDPointerCardTitle : styles.ADHDPointerCardTitle
            }
          >
            {item.title}
          </p>
          <p
            className={isiOS ? iStyles.Description : styles.Description}
            style={{ marginTop: 10 * hRatio }}
          >
            {item.description}
          </p>
        </div>
      </div>
    ));
  };
  return (
    <div className={styles.Container}>
      <div className={styles.CardContainer}>
        <p className={isiOS ? iStyles.Title : styles.Title}>Who are we?</p>
        <p className={isiOS ? iStyles.Description : styles.Description}>
          we believe that mental health care should be{" "}
          <span style={{ fontWeight: 700 }}>
            accessible, evidence-based, and personalised.
          </span>
        </p>
        <div className={styles.InnerCard}>
          <div>
            <p className={isiOS ? iStyles.Title : styles.Title}>Our mission</p>
            <p className={isiOS ? iStyles.Description2 : styles.Description2}>
              to provide{" "}
              <span style={{ fontWeight: 700 }}>
                high-quality mental health care that’s within reach
              </span>
              , no matter where you are or what challenges you're facing.
            </p>
          </div>
          <img
            src={require("../../../images/flower-pot.webp")}
            style={{ width: 116 * wRatio }}
          />
        </div>
      </div>
      <div style={{ marginTop: 154 * hRatio }}>
        <p className={isiOS ? iStyles.Title : styles.Title}>
          Why does getting a timely diagnosis for ADHD matter?
        </p>
        <p
          className={isiOS ? iStyles.Description : styles.Description}
          style={{ marginBottom: 14 * hRatio }}
        >
          A timely diagnosis of Adult ADHD can be life-changing
        </p>
      </div>
      {renderADHDPointers()}
    </div>
  );
};

export default WhoAreWe;
