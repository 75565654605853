import ReactPixel from "react-facebook-pixel";
import UserStore from "./UserStore";
import { triggerEvent } from "../constants/utils";

const { makeAutoObservable } = require("mobx");

class QuestionareStore {
  QUESTIONS = [
    {
      question:
        "How often do you have difficulty concentrating on what people are saying to you even when they are speaking to you directly?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
    {
      question:
        "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
    {
      isInformationalScreen: true,
    },
    {
      question:
        "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
    {
      isInformationalScreen: true,
    },
    {
      question:
        "When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to before they can finish them themselves?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
    {
      question: "How often do you put things off until the last minute?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
    {
      question:
        "How often do you depend on others to keep your life in order and attend to details?",
      options: [
        { text: "Never", score: 0 },
        { text: "Rarely", score: 1 },
        { text: "Sometimes", score: 2 },
        { text: "Often", score: 3 },
        { text: "Very Often", score: 4 },
      ],
    },
  ];

  currentStepIndex = 0;

  navigate = null;

  totalScores = 0;

  constructor() {
    makeAutoObservable(this);
  }

  selectOption(optionIndex) {
    const isMultiSelectStep =
      this.QUESTIONS[this.currentStepIndex].isMultiSelect;

    if (!isMultiSelectStep) {
      this.clearCurrentSelections();
    }

    this.QUESTIONS[this.currentStepIndex].options[optionIndex].isSelected =
      !this.QUESTIONS[this.currentStepIndex].options[optionIndex].isSelected;

    this.QUESTIONS[this.currentStepIndex].isComplete = this.isStepComplete();
    triggerEvent("Option_Select", {
      question: this.QUESTIONS[this.currentStepIndex].question,
      value: this.QUESTIONS[this.currentStepIndex].options[optionIndex].text,
    });
  }

  setNavigation(navigate) {
    this.navigate = navigate;
  }

  handleBackPress = () => {
    triggerEvent("Question_Back", {
      question: this.QUESTIONS[this.currentStepIndex],
      question_no: this.currentStepIndex + 1,
    });
    this.currentStepIndex -= 1;
  };

  handleNextPress = () => {
    if (this.currentStepIndex === 8) {
      triggerEvent("User_Email", {
        question: "Email Input",
        question_no: this.currentStepIndex + 1,
      });
      this.navigate("/adhd/score_preparation");
      return;
    }

    triggerEvent("Question_Next", {
      question: this.QUESTIONS[this.currentStepIndex],
      question_no: this.currentStepIndex + 1,
    });
    this.currentStepIndex += 1;
  };

  isStepComplete = () => {
    let isComplete = false;

    this.QUESTIONS[this.currentStepIndex].options.map((item) => {
      if (item.isSelected) {
        isComplete = true;
      }
    });

    return isComplete;
  };

  isNextButtonDisabled = (isQuestion) => {
    //email input screen
    if (this.currentStepIndex == 8) {
      return !UserStore.email || !UserStore.isEmailValid;
    } else if (isQuestion) {
      return !this.QUESTIONS[this.currentStepIndex].isComplete;
    }
    //information screens
    else {
      return false;
    }
  };

  clearCurrentSelections = () => {
    this.QUESTIONS[this.currentStepIndex].options.map((item, index) => {
      this.QUESTIONS[this.currentStepIndex].options[index].isSelected = false;
    });
  };

  getTotalScore = () => {
    let scores = 0;

    this.QUESTIONS?.map((item) => {
      item?.options?.map((option) => {
        if (option.isSelected) {
          scores += option.score;
        }
      });
    });

    this.totalScores = scores;
  };
}

export default new QuestionareStore();
