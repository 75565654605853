import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import ActionButton from "../../components/ActionButton";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { getMobileOperatingSystem, hRatio, triggerEvent } from "../../constants/utils";
import GoogleAward from "../../assets/welcome_screen/google_play_award.webp";
import AppleEditor from "../../assets/welcome_screen/apple_editor_choice.webp";

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  useEffect(() => {
    triggerEvent("WTA-PageView");
  }, []);

  const handleBtnPress = () => {
    navigate("/questionare");
    triggerEvent("WTA-Welcome_Screen_Btn");
  };

  return (
    <div className={styles.WelcomeScreenBG}>
      <img
        src={require("../../assets/welcome_screen/welcome_screen.webp")}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      />
      <div style={{ width: "100%" }}>
        <p className={isiOS ? iStyles.AdhdTitle1 : styles.AdhdTitle1}>
          1+ million people
        </p>
        <p className={isiOS ? iStyles.AdhdTitle2 : styles.AdhdTitle2}>
          are using the Evolve app
        </p>
        <div className={styles.Divider} />
        <p className={isiOS ? iStyles.AdhdTitle3 : styles.AdhdTitle3}>
          Mental health made inclusive,
          <p style={{ margin: 0 }}>safe & joyful!</p>
        </p>
        <img
          src={GoogleAward}
          style={{ height: 222 * hRatio, marginTop: 298 * hRatio }}
        />
        <img
          src={AppleEditor}
          style={{ height: 222 * hRatio, marginTop: 298 * hRatio }}
        />
      </div>
      <ActionButton
        text={"Start your journey, it’s free"}
        onClick={handleBtnPress}
        containerStyle={{ bottom: 185 * hRatio }}
      />
      <div className={styles.FooterContainer}>
        <p className={styles.TncText}>
          <a
            href="https://evolveinc.io/privacy/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Privacy Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/terms/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Terms and Conditions |{" "}
          </a>
          <a
            href="https://evolveinc.io/return-refund/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Refund Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/shop/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Deliverable Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/get-in-touch/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Contact Us
          </a>
        </p>
        <p className={styles.CompanyName}>
          GTA Solutions Private Limited, all rights reserved
        </p>
      </div>
    </div>
  );
};

export default WelcomeScreen;
