import { useState } from "react";

import { getMobileOperatingSystem, hRatio } from "../../constants/utils";
import TextInput from "../../components/TextInput";
import ActionButton from "../../components/ActionButton";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { useNavigate } from "react-router-dom";
import UserStore from "../../stores/UserStore";
import Evolve from "../../icons/Evolve";

const isiOS = getMobileOperatingSystem() === "iOS";
const InputContainerStyle = {
  marginTop: 134 * hRatio,
  backgroundColor: "#f6f1e5",
};

const InputStyle = {
  backgroundColor: "#f6f1e5",
  height: isiOS ? 198 * hRatio : 172 * hRatio,
  fontSize: isiOS ? 69 * hRatio : 54 * hRatio,
};

const InputLabelStyle = {
  backgroundColor: "#f6f1e5",
  height: 48 * hRatio,
  fontSize: isiOS ? 48 * hRatio : 39 * hRatio,
};

const InputErrorStyle = {
  fontSize: isiOS ? 48 * hRatio : 39 * hRatio,
};

const SignUp = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: null, password: null });
  const isFormValid = formValues.password && !errors.password;
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
      setErrors({ email: "Enter valid email address" });
    } else {
      setErrors({ email: "" });
    }
    setFormValues((prev) => ({ ...prev, email: event.target.value }));
  };

  const handlePasswordChange = (event) => {
    setFormValues((prev) => ({ ...prev, password: event.target.value }));
    UserStore.setFields("password", event.target.value);
  };

  const handleNavigation = () => {
    navigate("/download");
    UserStore.updateUserEmail();
  };

  return (
    <div className={Styles.SignUpBG}>
      <div className={Styles.EvolveIcon}>
        <Evolve />
      </div>
      <div style={{ height: 216 * hRatio }} />
      <p className={isiOS ? iStyles.SignUpTitle :  Styles.SignUpTitle}>You are all set!</p>
      <p className={isiOS ? iStyles.SignUpSubtitle : Styles.SignUpSubtitle}>Complete your account to get started</p>
      <img
        src={require("../../assets/sign_up/sign_up.webp")}
        style={{
          height: "100%",
          width: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <TextInput
        id="email"
        title={"your email *"}
        placeholder={UserStore.email}
        style={InputContainerStyle}
        onChange={() => {}}
        error={""}
        type={"email"}
        isEditable={false}
        inlineCustomStyle={` 
                    #email::placeholder { 
                        color: rgba(0, 0, 0, 0.63); 
                    }`}
        inputStyle={InputStyle}
        labelStyle={InputLabelStyle}
        errorStyle={InputErrorStyle}
      />
      <TextInput
        title={"password *"}
        placeholder={"enter password"}
        style={{ ...InputContainerStyle, marginTop: 102 * hRatio }}
        onChange={handlePasswordChange}
        error={errors.password}
        type={"password"}
        inputStyle={InputStyle}
        labelStyle={InputLabelStyle}
        errorStyle={InputErrorStyle}
      />
      <ActionButton
        text={"Get started"}
        disabled={!isFormValid}
        onClick={handleNavigation}
      />
    </div>
  );
};

export default SignUp;
