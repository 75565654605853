import * as React from "react";

import { hRatio, wRatio } from "../constants/utils";

const Cloud2 = ({ height = 127, width = 137 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width * wRatio}
    height={height * hRatio}
    viewBox="0 0 137 127"
    fill="none"
  >
    <path
      fill="#BCDBF6"
      fillRule="evenodd"
      d="M20.63 126.844c-4.9.202-10.052.362-14.447-1.407-4.392-1.769-7.585-6.178-5.56-9.8 1.221-2.192 3.957-3.545 6.641-4.563 11.049-4.19 23.867-5.039 34.239-10.24 15.692-7.869 22.774-24.138 38.707-31.682 19.28-9.13 44.505-2.499 65.543-8.572 30.953-8.938 42.052-41.238 70.696-54.297 20.423-9.313 45.953-7 68.298-1.276 14.451 3.697 28.997 9.07 38.304 18.765 5.013 5.222 8.4 11.59 14.749 15.748 14.425 9.448 35.678 3.322 54.114 4.956 35.514 3.148 57.643 34.916 92.211 42.231 15.977 3.38 33.417 1.194 48.833 5.99 4.395 1.369 8.714 3.428 11.124 6.707 4.904 6.659-.299 15.771-8.482 19.786-8.181 4.018-18.247 4.304-27.811 4.46-131.203 2.135-455.497 1.895-487.159 3.194Z"
      clipRule="evenodd"
      opacity={0.55}
    />
  </svg>
);
export default Cloud2;
