export const ADHD_Pointers = [
  {
    title: "Gain Clarity:",
    description:
      "Understand whether your symptoms align with ADHD or are related to other conditions.",
    icon: require("../../images/calm-emoji.webp"),
  },
  {
    title: "Receive tailored interventions:",
    description:
      "Once diagnosed, personalised interventions can improve focus, time management, and overall functioning in both personal and professional settings.",
    icon: require("../../images/pencil-emoji.webp"),
  },
  {
    title: "Access support:",
    description:
      "Once diagnosed, personalised interventions can improve focus, time management, and overall functioning in both personal and professional settings.",
    icon: require("../../images/heart-emoji.webp"),
  },
];

export const PREMIUM_PLANS = [
  // {
  //   id: 1,
  //   price: "449",
  //   benefits: ["Assessment", "Report", "3 therapy (60 mins)"],
  //   recommended: true,
  //   amount: "44900",
  // },
  {
    id: 2,
    price: "199",
    benefits: ["Assessment", "Report", "Consult (30 mins)"],
    amount: "19900",
  },
  {
    id: 3,
    price: "149",
    benefits: ["Assessment", "Report"],
    amount: "14900",
  },
];
