import { Observer } from "mobx-react";

import QuestionareStore from "../../stores/QuestionareStore";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { getMobileOperatingSystem } from "../../constants/utils";

const Footer = ({ isQuestionScreen }) => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  return (
    <Observer>
      {() => {
        const isNextDisabled =
          QuestionareStore.isNextButtonDisabled(isQuestionScreen);
        const isBackDisabled = QuestionareStore.currentStepIndex == 0;

        return (
          <div>
            <input
              type="button"
              value="back"
              className={isiOS ? iStyles.BackBtn : styles.BackBtn}
              style={{ opacity: isBackDisabled ? 0.5 : 1 }}
              disabled={isBackDisabled}
              onClick={() => QuestionareStore.handleBackPress()}
            />
            <input
              type="button"
              value="next"
              className={isiOS ? iStyles.NextBtn : styles.NextBtn}
              style={{ opacity: isNextDisabled ? 0.5 : 1 }}
              disabled={isNextDisabled}
              onClick={() => QuestionareStore.handleNextPress()}
            />
          </div>
        );
      }}
    </Observer>
  );
};

export default Footer;
