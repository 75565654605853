import {
  getMobileOperatingSystem,
  hRatio,
  wRatio,
} from "../../../constants/utils";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const CostBreakUp = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  return (
    <div className={styles.CostTableContainer}>
      <div className={styles.Column1}>
        <p
          className={isiOS ? iStyles.TableHeading : styles.TableHeading}
          style={{ opacity: 0 }}
        >
          .
        </p>
        <p
          className={isiOS ? iStyles.TableHeading : styles.TableHeading}
          style={{
            padding: `${60 * hRatio}px 0px ${59 * hRatio}px 0px`,
          }}
        >
          Cost
        </p>
        <p
          className={isiOS ? iStyles.TableHeading : styles.TableHeading}
          style={{
            padding: `${60 * hRatio}px 0px ${59 * hRatio}px 0px`,
          }}
        >
          Time
        </p>
      </div>
      <div className={styles.Column2}>
        <p
          className={isiOS ? iStyles.TableHeading : styles.TableHeading}
          style={{
            backgroundColor: "#bcdbf6",
            borderRight: "1px solid rgba(0, 0, 0, 0.21)",
            borderTopLeftRadius: 24 * wRatio,
          }}
        >
          Other providers
        </p>
        <p
          className={isiOS ? iStyles.TableContent : styles.TableContent}
          style={{
            borderRight: "1px solid rgba(0, 0, 0, 0.21)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.21)",
          }}
        >
          upto $5000
        </p>
        <p
          className={isiOS ? iStyles.TableContent : styles.TableContent}
          style={{
            borderRight: "1px solid rgba(0, 0, 0, 0.21)",
            borderBottomLeftRadius: 24 * wRatio,
          }}
        >
          3-6 months
        </p>
      </div>
      <div className={styles.Column3}>
        <p
          className={isiOS ? iStyles.TableHeading : styles.TableHeading}
          style={{
            backgroundColor: "#bcdbf6",
            borderTopRightRadius: 24 * wRatio,
          }}
        >
          Embrace
        </p>
        <p
          className={isiOS ? iStyles.TableContent : styles.TableContent}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.21)" }}
        >
          $299
        </p>
        <p
          className={isiOS ? iStyles.TableContent : styles.TableContent}
          style={{ borderBottomRightRadius: 24 * wRatio }}
        >
          72 hrs
        </p>
      </div>
    </div>
  );
};

export default CostBreakUp;
