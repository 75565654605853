import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useEffect } from "react";

import styles from "./style.module.css";
import iStyles from "./iStyles.module.css";
import Background from "../../components/Background";
import ActionButton from "../../components/ActionButton";
import {
  getMobileOperatingSystem,
  hRatio,
  triggerEvent,
  wRatio,
} from "../../constants/utils";

const Welcome = () => {
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  const handleActionBtnPress = () => {
    navigate("/adhd/questionare");
    triggerEvent("Welcome_Screen_Btn");
  };

  return (
    <Background>
      <div className={styles.WelcomeContent}>
        <p className={isiOS ? iStyles.AdhdTitle1 : styles.AdhdTitle1}>
          10.5+ million U.S. adults
        </p>
        <p className={isiOS ? iStyles.AdhdTitle2 : styles.AdhdTitle2}>
          are affected by ADHD
        </p>
        <div className={styles.Divider} />
        <p className={isiOS ? iStyles.AdhdTitle3 : styles.AdhdTitle3}>
          Get screened today!
        </p>
        <ActionButton
          text={"Start your free ADHD assessment"}
          containerStyle={{
            padding: `0px ${92 * wRatio}px`,
            bottom: 105 * hRatio,
          }}
          onClick={handleActionBtnPress}
        />
      </div>
    </Background>
  );
};

export default Welcome;
