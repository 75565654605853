import "./styles.css";

const LinearProgress = ({ progress }) => {
  return (
    <div className="Bar">
      <div className="FillBar" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default LinearProgress;
